import React from "react";
import { Box } from "@src/components/Boxes";
import Container from "@src/components/Container";
import BlogArticle from "@src/components/blog/BlogArticle";
import NextPrevNav from "@src/components/blog/NextPrevNav";
import BlogSEO from "@src/components/blog/BlogSEO";

const Blog = ({ pageContext }) => {
  const { previousPagePath, nextPagePath, items, pagePath } = pageContext;

  return (
    <Box backgroundColor="#f5f7f9" overflow="auto">
      <BlogSEO slug={pagePath} />
      <Box backgroundColor="#f5f7f9" paddingTop={["90px", "95px"]}>
        <Container>
          <Box width={["100%", "100%", 10 / 12, 8 / 12]} mx="auto" px={[0, "15px"]}>
            {items.map((post, index) => (
              <BlogArticle key={`postpreview-${index}`} post={post} showExcerpt />
            ))}
            <NextPrevNav nextLink={nextPagePath} prevLink={previousPagePath} />
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Blog;
